










































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EmergencyFundSummaryViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/emergency-fund/emergency-fund-summary-view-model';

@Component({
  name: 'EmergencyFundSummary',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EmergencyFundSummary extends Vue {
  view_model = Vue.observable(
    new EmergencyFundSummaryViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
